import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Fabrication`}</h4>
    <p>{`Fabrication turns designs into chips. Semiconductor fabrication facilities ("fabs‚") make chips in these wafers in two steps: forming transistors and other electrical devices in material layers within silicon wafers; and forming metal interconnects between the electrical devices in insulating layers above the silicon.`}</p>
    <p>{`There are two business models for fabs: (1) fabs owned by integrated device manufacturers ("IDMs"), which manufacture chips based on their own designs; and (2) foundries, i.e., fabs operating independently and manufacturing chips for third-party customers. Firms headquartered in the United States, Taiwan, South Korea, Japan, and China control the vast majority of the world's fab market share and fab capacity-most of which is also physically located in these countries. Three firms - headquartered in the United States (Intel), Taiwan (TSMC), and South Korea (Samsung) - control virtually all of the world's advanced logic fab capacity, with the most cutting-edge fabs concentrated in Taiwan and South Korea.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      